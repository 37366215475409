import { useVinconnectFlags } from "./lib/useFlags";
import useVinconnectFlagEnabled from "./lib/useVinconnectFlagEnabled";

export * from "./lib/toggles";
export * from "./lib/useFlags";
export * from "./lib/useVinconnectFlagEnabled";
export * from "./lib/useOemLaunchpadFlagEnabled";
export const LaunchDarklyVinconnect = {
  useVinconnectFlagEnabled,
  useVinconnectFlags
};
