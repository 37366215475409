import {
  getGTMNotReadyConfig,
  getNewRelicConfig
} from "@vinsolutions/ccrm/util";
import { TagManager } from "@vinsolutions/core-third-party-gtm";
import { NewRelic } from "@vinsolutions/core/third-party/newrelic";
import { createLogger } from "@vinsolutions/logger";

const logger = createLogger("MainHelper");

export const initialize = () => {
  logger.debug("MainHelper: Initializing MainHelper.");
  const initializeNewRelic = () => {
    logger.debug("MainHelper: Initializing NewRelic.");
    NewRelic.initialize(getNewRelicConfig());
    logger.debug("MainHelper: NewRelic initialized.");
  };
  initializeNewRelic();
  const initializeGTM = () => {
    logger.debug("MainHelper: Initializing GTM.");
    TagManager.initialize(getGTMNotReadyConfig());
    logger.debug("MainHelper: GTM initialized.");
  };
  initializeGTM();
};
