import { useFlags as useFlagsReal } from "launchdarkly-react-client-sdk";
import {
  OemLaunchpadFlagNames,
  TestFlagNames,
  VinconnectFlagNames
} from "./toggles";

// export const useFlags = useFlagsReal as () => launchDarklyToggles;

export type GenericFlags<FlagNames extends string> = Record<FlagNames, boolean>;

export const useTestFlags = useFlagsReal as () => GenericFlags<TestFlagNames>;

export const useVinconnectFlags =
  useFlagsReal as () => GenericFlags<VinconnectFlagNames>;

export const useOemLaunchpadFlags =
  useFlagsReal as () => GenericFlags<OemLaunchpadFlagNames>;
