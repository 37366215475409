/* eslint-disable @nx/enforce-module-boundaries */
import { RouteList } from "./route-config-types";
import {
  blocklistRoute,
  crwDealListRoute,
  customerDataTransferPageWithCustomerRoute,
  dealerDashboardRoute,
  dealerDashboardWithCustomerRoute,
  dealerDashboardWithUnansweredEmailRepliesRoute,
  dealerManufacturerCodesRoute,
  domainSetupValidationRoute,
  taskListRoute
} from "./route-definitions";

/* ---------------- Add your route import above this line ------------------*/

/**
 * In your component use the RouteBuilder to build your route.
 * Import your route above.
 * Add your new route to the list below.
 */
export const routeList: RouteList = [
  blocklistRoute,
  crwDealListRoute,
  dealerDashboardRoute,
  dealerDashboardWithCustomerRoute,
  dealerDashboardWithUnansweredEmailRepliesRoute,
  dealerManufacturerCodesRoute,
  domainSetupValidationRoute,
  taskListRoute,
  customerDataTransferPageWithCustomerRoute
  /* -------------- Add your route above this line ----------------*/
];
