import { useEffect } from "react";
import { environments, getConfig } from "@vinsolutions/core/config";
import { LaunchDarklyVinconnect } from "@vinsolutions/core/third-party/launch-darkly";
import { getProfileState } from "@vinsolutions/ccrm/store";
import { shallowEqual, useSelector } from "react-redux";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { isUserLoggedIntoBridge } from "@vinsolutions/tenant/bridge-bar";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OidcUser } from "@vinsolutions/tenant/jwt-utils";
import { getUserBridgeState } from "@vinsolutions/tenant/store";
import { useReactOidc } from "@axa-fr/react-oidc-context";

declare global {
  interface Window {
    pixallConfig?: {
      smartLoad: boolean;
    };
    _pixall: {
      removePixall(): void;
    };
    coxAutoDataLayer: any[];
  }
}

export const PixallCdl = () => {
  const { oidcUser } = useReactOidc();
  const env = getConfig().environmentShortName ?? "";
  const isProduction = env.toLowerCase() === environments.prod.toLowerCase();

  const {
    bridgeUsername,
    platformId,
    loadingStatus: bridgeStateLoadingStatus
  } = useSelector(getUserBridgeState, shallowEqual);

  const pixallToggle = LaunchDarklyVinconnect.useVinconnectFlagEnabled(
    "nx.vinconnect.ui.pixall.enabled"
  );

  const {
    dealerId,
    dealerName,
    isVinEmployee,
    profileLoadingStatus,
    userRole,
    userId
  } = useSelector(getProfileState, shallowEqual);

  useEffect(() => {
    const pixallScriptNode = document.getElementById("pixall-script-tag");

    const pixallURL =
      env === "Prod"
        ? "https://assets.prod.analytics.dealer.com/pixall/cdl.min.js"
        : "https://assets.non-prod.analytics.dealer.com/pixall/cdl.min.js";

    if (pixallToggle) {
      if (!pixallScriptNode) {
        const pixallScript = document.createElement("script");
        pixallScript.id = "pixall-script-tag";
        pixallScript.innerHTML = `(function(w,d,s,l){w[l]=w[l]||[];
        w[l].push();
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.type="text/javascript";j.setAttribute('data-testid','pixall-script-tag');j.setAttribute('defer', 'true');
        j.src='${pixallURL}';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','coxAutoDataLayer');`;
        const head = document.getElementsByTagName("head")[0];
        head.appendChild(pixallScript);

        window.pixallConfig = { smartLoad: true };
      }

      if (
        (isUserLoggedIntoBridge(oidcUser as unknown as OidcUser) &&
          profileLoadingStatus === "loaded" &&
          bridgeStateLoadingStatus === "loaded") ||
        (!isUserLoggedIntoBridge(oidcUser as unknown as OidcUser) &&
          profileLoadingStatus === "loaded")
      ) {
        window.coxAutoDataLayer = window.coxAutoDataLayer || [];
        window.coxAutoDataLayer.push({
          application: {
            name: "VinSolutionsUI",
            environment: env,
            componentId: "CI2355668",
            brand: "VinSolutions",
            solution: "CRM Core",
            workloadId: "CI0433780",
            isProduction
          },
          user: {
            bridgeUsername,
            bridgePlatformId: platformId,
            dealerUserId: userId ?? 0,
            type: isVinEmployee ? "CAI Employee" : "dealer",
            isInternalUser: isVinEmployee ? true : false
          },

          client: {
            vinsolutionsId: (dealerId ?? 0).toString(),
            name: dealerName ?? "",
            type: "Dealer"
          }
        });
      }
    } else {
      const pixallScriptNodeExists =
        document.getElementById("pixall-script-tag");
      if (pixallScriptNodeExists) {
        pixallScriptNodeExists.remove();
        if (window._pixall) {
          window._pixall.removePixall();
        }
      }
    }
  }, [
    bridgeStateLoadingStatus,
    bridgeUsername,
    dealerId,
    dealerName,
    env,
    isProduction,
    isVinEmployee,
    oidcUser,
    pixallToggle,
    platformId,
    profileLoadingStatus,
    userId,
    userRole
  ]);

  return null;
};
